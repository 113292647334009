<template>
  <div class="bntv__dropdown">
    <b-dropdown id="menu" :text="nameWithEllipsis" right>
      <div v-if="isAdmin">
        <b-dropdown-item v-b-modal.transmision>
          {{ eventMenuLabel }}
        </b-dropdown-item>
        <b-dropdown-item v-b-modal.connections
          >Ver usuarios conectados</b-dropdown-item
        >
        <b-dropdown-item v-b-modal.invite>Enviar invitaciones</b-dropdown-item>
        <b-dropdown-item v-b-modal.manage-invites
          >Administrar Invitaciones</b-dropdown-item
        >
        <b-dropdown-item v-on:click="dropGuests()"
          >Desconectar invitados</b-dropdown-item
        >
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item v-b-modal.survey-enablement>
          Habilitación de Encuesta
        </b-dropdown-item>
        <b-dropdown-item>
          <router-link to="group" class="link">
            Administrar Grupos
          </router-link>
        </b-dropdown-item>

        <b-dropdown-item>
          <router-link to="user-group" class="link">
            Asignar Grupos
          </router-link>
        </b-dropdown-item>

        <b-dropdown-divider></b-dropdown-divider>
      </div>

      <b-dropdown-item v-b-modal.changePassword v-if="!isGuest">
        Cambiar contraseña
      </b-dropdown-item>

      <b-dropdown-item>
        <a id="reportar" class="link" href="#">Reportar un problema</a>
      </b-dropdown-item>

      <b-dropdown-item @click="logout">Salir</b-dropdown-item>
      <template>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item>
          <span class="text-muted">v{{ version }}</span>
        </b-dropdown-item>
      </template>
    </b-dropdown>

    <template v-if="isAdmin">
      <survey-enablement ref="survey_enablement"></survey-enablement>
      <toggle-event></toggle-event>
      <invite-guest></invite-guest>
      <manage-invites></manage-invites>
      <connections></connections>
    </template>
    <change-password v-if="!isGuest"></change-password>
  </div>
</template>
<script>
import jQuery from "jquery";
import ToggleEvent from "./ToggleEvent.vue";
import InviteGuest from "./InviteGuest.vue";
import ManageInvites from "./ManageInvites.vue";
import Connections from "./Connections";
import ChangePassword from "./ChangePassword";
import { version } from "../../package";
import guests from "../mixins/guests";
import { mapGetters } from "vuex";
import authService from "@/services/auth";
import SurveyEnablement from "./SurveyEnablement.vue";

window.jQuery = jQuery;

export default {
  components: {
    ToggleEvent,
    InviteGuest,
    ManageInvites,
    Connections,
    ChangePassword,
    SurveyEnablement,
  },
  data: function () {
    return {
      version: version,
      windowWidth: window.innerWidth,
    };
  },
  mixins: [guests],
  computed: {
    ...mapGetters({
      event: "events/getActiveEvent",
    }),
    eventMenuLabel: function () {
      return this.event.id ? "Actualizar o Terminar Evento" : "Iniciar Evento";
    },
    isGuest: function () {
      return this.$root.user?.invitacion_id;
    },
    nameWithEllipsis: function () {
      if (this.$root.user?.display_name) {
        if (
          this.$root.user.display_name.length > 15 &&
          this.windowWidth < 480
        ) {
          return this.$root.user.display_name.substring(0, 15) + "...";
        }
        return this.$root.user?.display_name;
      }
      return "";
    },
    isAdmin: function () {
      return this.$root.capabilities && this.$root.capabilities == 10;
    },
  },
  mounted: function () {
    let user = this.$root.user;

    jQuery.ajax({
      url:
        "https://mlacdev.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/sb53l8/b/24/e73395c53c3b10fde2303f4bf74ffbf6/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector-embededjs/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector-embededjs.js?locale=es-ES&collectorId=fc5dc2f6",
      type: "get",
      cache: true,
      dataType: "script",
    });

    window.ATL_JQ_PAGE_PROPS = {
      triggerFunction: function (showCollectorDialog) {
        jQuery("#reportar").click(function (e) {
          e.preventDefault();
          showCollectorDialog();
        });
      },
      fieldValues: {
        fullname: user?.display_name,
        email: user?.user_email,
      },
    };

    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
  },
  methods: {
    logout: function () {
      authService.logout(this.$root.socket, this.$router);
      delete this.$root.user;
    },
  },
};
</script>
<style scoped>
.link {
  color: black;
}

.link:hover {
  text-decoration: none;
}

@media only screen and (max-width: 768px) {
  .bntv__dropdown {
    z-index: 1300;
    position: relative;
  }
}

.dropdown_item {
  color: #fff;
  z-index: 101;
}
</style>
